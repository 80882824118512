
import { Options, Vue } from 'vue-class-component';

import CookieContent from '@/components/CookieContent.vue';

@Options({
  components: {
    CookieContent,
  },
})
export default class CookiePolicy extends Vue {}
